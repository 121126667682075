import React from 'react';
import PropTypes from 'prop-types';

const FieldTemplate = (props) => {
  const {
    classNames,
    label,
    help,
    required,
    description,
    errors,
    children,
    uiSchema,
  } = props;

  const showErrors =
    uiSchema && uiSchema['ui:showErrors'] != null
      ? uiSchema['ui:showErrors']
      : true;

  return (
    <div className={classNames}>
      {label && (
        <h3>
          {label}
          {required ? '*' : null}
        </h3>
      )}
      {description}
      {children}
      {showErrors && errors}
      {help}
    </div>
  );
};

FieldTemplate.propTypes = {
  classNames: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.object,
  showErrors: PropTypes.bool,
  required: PropTypes.bool,
  description: PropTypes.object,
  errors: PropTypes.node,
  children: PropTypes.node,
  uiSchema: PropTypes.shape({
    'ui:showErrors': PropTypes.bool,
  }),
};

export default FieldTemplate;
