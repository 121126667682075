import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import CheckboxWidget from './CheckboxWidget';
import FieldTemplate from './FieldTemplate';

import './index.css';

const widgets = { CheckboxWidget };

const FormComponent = (props) => {
  const { formData, schema, uiSchema, onChange, showErrorList } = props;

  return (
    <Form
      formData={formData}
      schema={schema}
      uiSchema={uiSchema}
      onChange={onChange}
      widgets={widgets}
      FieldTemplate={FieldTemplate}
      showErrorList={showErrorList}
    >
      {props.children}
    </Form>
  );
};

FormComponent.propTypes = {
  formData: PropTypes.object,
  schema: PropTypes.object,
  uiSchema: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node,
  showErrorList: PropTypes.bool,
};

export default FormComponent;
