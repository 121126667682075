import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import RestroomPopUp from './RestroomPopUp';
import { getMarkerIcon } from './helpers';

const RestroomMarkers = ({ offset, restrooms, zoom }) =>
  restrooms.map((restroom) => {
    const icon = getMarkerIcon('restroom', zoom, 'restroom');
    const { latitude, longitude } = restroom;

    return (
      <Marker position={[latitude, longitude]} key={restroom.id} icon={icon}>
        <RestroomPopUp restroom={restroom} offset={offset} />
        <Tooltip offset={[(zoom * 1.8) / 2, 0]} direction="right">
          {restroom.name}
        </Tooltip>
      </Marker>
    );
  });

RestroomMarkers.propTypes = {
  offset: PropTypes.arrayOf(PropTypes.number),
  restrooms: PropTypes.arrayOf(PropTypes.object),
  zoom: PropTypes.number,
};

export default RestroomMarkers;
