import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { detect } from 'detect-browser';

const ip = require('what-is-my-ip-address');

const checkLoggingEnabled = async (client) => {
  const settings = (
    await client.query({
      query: getSettingsQuery,
    })
  ).data.allSettings.nodes;

  const logSetting = settings.find((s) => s.key === 'loggingEnabled');

  return logSetting && logSetting.value === 'true';
};

const isEventDuplicate = (timeStamp) => {
  const eventTimestamp = window.localStorage.getItem('eventTimestamp');

  window.localStorage.setItem('eventTimestamp', timeStamp);

  return (
    eventTimestamp !== null &&
    parseFloat(eventTimestamp) === parseFloat(timeStamp)
  );
};

checkLoggingEnabled.propTypes = {
  client: PropTypes.object,
};

export const insertLog = async (client, input, options = {}) => {
  if (options.eventTimestamp && isEventDuplicate(options.eventTimestamp))
    return;

  const shouldLog = await checkLoggingEnabled(client);

  if (shouldLog) {
    const userId = window.localStorage.getItem('userUniqueId');

    const log = (sessionId) => {
      client.mutate({
        mutation: insertLogMutation,
        variables: {
          input: {
            ...input,
            userId,
            sessionId,
          },
        },
      });
    };

    const createSession = (ipAddress) => {
      const browser = detect();
      client
        .mutate({
          mutation: insertSessionMutation,
          variables: {
            input: {
              ipAddress,
              browser: browser ? browser.name + ' ' + browser.version : null,
              os: browser ? browser.os : null,
              userId,
            },
          },
        })
        .then(({ data }) => {
          const sessionId = data.insertSession.session.id;
          log(sessionId);
          window.localStorage.setItem('sessionId', sessionId);
          if (options.updateSessionId) options.updateSessionId(sessionId);
        });
    };

    const { sessionId } = options;

    if (sessionId) {
      log(sessionId);
    } else {
      ip.v4()
        .then((ipAddress) => {
          createSession(ipAddress);
        })
        .catch(() => {
          createSession(null);
        });
    }
  }
};

insertLog.propTypes = {
  client: PropTypes.object,
};

const insertSessionMutation = gql`
  mutation insertSession($input: InsertSessionInput!) {
    insertSession(input: $input) {
      session {
        id: rowId
      }
    }
  }
`;

const insertLogMutation = gql`
  mutation insertLog($input: InsertLogInput!) {
    insertLog(input: $input) {
      log {
        id: rowId
      }
    }
  }
`;

const getSettingsQuery = gql`
  query getSettings {
    allSettings {
      nodes {
        id
        key
        value
      }
    }
  }
`;
