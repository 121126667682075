import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flatMap from 'lodash/flatMap';
import { CustomMap } from '../../../components';

import './index.css';

class Map extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    defaultZoom: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    restaurants: PropTypes.arrayOf(PropTypes.object),
    restrooms: PropTypes.arrayOf(PropTypes.object),
    showPopUp: PropTypes.bool,
    wasStationUnselected: PropTypes.bool,
  };

  static defaultProps = {
    defaultZoom: 10,
  };

  render() {
    const {
      data,
      defaultZoom,
      latitude,
      longitude,
      restaurants,
      restrooms,
      showPopUp,
      wasStationUnselected,
    } = this.props;
    const position = [latitude, longitude];
    const dataCoords = flatMap(
      data.map((d) => d.stations.map((s) => [s.latitude, s.longitude]))
    );

    return (
      <CustomMap
        data={data}
        dataCoords={dataCoords}
        defaultZoom={defaultZoom}
        position={position}
        restaurants={restaurants}
        restrooms={restrooms}
        showPopUp={showPopUp}
        wasStationUnselected={wasStationUnselected}
      />
    );
  }
}

export default Map;
