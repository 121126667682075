import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';
import { Link, withRouter } from 'react-router-dom';

import './StationPopUp.css';

const StationPopUp = ({
  isElevatorOut,
  match,
  offset,
  organization,
  station,
}) => {
  const isBikeRackAvailable = station.bike_rack;
  const isParkingAvailable = station.parking;
  const isAccessible = station.wheelchair_accessible;
  const { city, state } = match.params;

  return (
    <Popup className="station-pop-up" offset={offset}>
      <div>
        <h4>
          {station.name}
          <span>Operated By {organization}</span>
        </h4>
        <div className="icons">
          {isAccessible && <i className="fa fa-wheelchair-alt" />}
          {isBikeRackAvailable && <i className="fa fa-bicycle" />}
          {isParkingAvailable && <i className="fa fa-car" />}
        </div>
        {isElevatorOut && (
          <div className="elevator-out">
            <i className="fa fa-warning" />
            Elevator Outage Reported
          </div>
        )}
        <Link
          className="btn bt-primary"
          to={`/cities/${state}/${city}/station/${encodeURIComponent(
            station.line.toLowerCase()
          )}/${encodeURIComponent(station.name.toLowerCase())}/${station.id}`}
        >
          View Station
        </Link>
      </div>
    </Popup>
  );
};

StationPopUp.propTypes = {
  isElevatorOut: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }),
  }),
  offset: PropTypes.arrayOf(PropTypes.number),
  organization: PropTypes.string,
  station: PropTypes.shape({
    bike_rack: PropTypes.bool,
    id: PropTypes.string,
    line: PropTypes.string,
    name: PropTypes.string,
    parking: PropTypes.bool,
    wheelchair_accessible: PropTypes.bool,
  }),
};

export default withRouter(StationPopUp);
