import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import flowRight from 'lodash/flowRight';
import StationPopUp from './StationPopUp';
import { getIconClass, getMarkerIcon } from './helpers';

import './StationMarkers.css';

const StationMarkers = ({
  data,
  elevatorOutages,
  offset,
  zoom,
  showPopUp = true,
}) => {
  const stationsWithElevatorOutages = elevatorOutages.allElevatorOutages
    ? elevatorOutages.allElevatorOutages.nodes.map((e) => ({
        line: e.line,
        station: e.station,
      }))
    : [];

  return data.map((line) =>
    line.stations.map((station, index) => {
      const isElevatorOut = stationsWithElevatorOutages.find(
        (s) =>
          s.line.toLowerCase() === line.name.toLowerCase() &&
          s.station.toLowerCase() === station.name.toLowerCase()
      );

      const markerClass = getIconClass(
        station.wheelchair_accessible,
        isElevatorOut
      );

      const icon = getMarkerIcon(line.icon_type, zoom, markerClass);
      const latitude = parseFloat(station.latitude);
      const longitude = parseFloat(station.longitude);

      return (
        <Marker position={[latitude, longitude]} key={index} icon={icon}>
          {showPopUp && (
            <StationPopUp
              isElevatorOut={!!isElevatorOut}
              offset={offset}
              station={station}
              organization={line.organization.name}
            />
          )}
          <Tooltip offset={[(zoom * 1.8) / 2, 0]} direction="right">
            {station.name}
          </Tooltip>
        </Marker>
      );
    })
  );
};

StationMarkers.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      line: PropTypes.shape({
        stations: PropTypes.arrayOf(PropTypes.object),
        icon_type: PropTypes.string,
        organization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    })
  ),
  offset: PropTypes.arrayOf(PropTypes.number),
  markerOnClick: PropTypes.func,
  zoom: PropTypes.number,
};

const elevatorOutagesQuery = gql`
  query($city: String) {
    allElevatorOutages(
      filter: { city: { equalTo: $city }, parsed: { equalTo: false } }
    ) {
      nodes {
        line
        elevator
        station
        message
        city
        transitAuthority
        id
        lastUpdatedAt
        parsed
      }
    }
  }
`;

export default flowRight(
  withRouter,
  graphql(elevatorOutagesQuery, {
    name: 'elevatorOutages',
    options: (props) => ({
      variables: {
        city: props.match.params.city,
      },
    }),
    fetchPolicy: 'network-only',
  })
)(StationMarkers);
