import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flowRight from 'lodash/flowRight';
import orderBy from 'lodash/orderBy';
import { FaGithub, FaLinkedinIn, FaTwitter, FaGlobe } from 'react-icons/fa';
import { withGoogleSheets } from 'react-db-google-sheets';
import { withRouter } from 'react-router-dom';
import { Footer, MetaTags, withTitle } from '../../components';

import './About.css';

const meta = {
  description:
    'Learn more about the project, and meet the contributors who created UnlockedMaps.',
  title: 'About',
};

const contributorImageBaseUrl =
  process.env.PUBLIC_URL + '/images/contributors/';

const imageExists = (userId, callback) => {
  const image = new Image();

  image.onload = () => callback(userId, true);
  image.onerror = () => callback(userId, false);

  image.src = contributorImageBaseUrl + userId + '.jpg';
};

class About extends Component {
  static propTypes = {
    db: PropTypes.shape({
      contributors: PropTypes.arrayOf(PropTypes.object),
    }),
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const {
      db: { contributors },
    } = props;

    let images = {};

    contributors.forEach((c) => {
      images[c.user_id] = 'default_profile.png';
    });

    this.state = { images };
  }

  updateImageState = (userId, imageExists) => {
    if (imageExists) {
      this.setState({
        images: {
          ...this.state.images,
          [userId]: userId + '.jpg',
        },
      });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const { images } = this.state;

    Object.keys(images).forEach((userId) => {
      imageExists(userId, this.updateImageState);
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const {
      db: { contributors },
    } = this.props;
    const { images } = this.state;

    const activeContributors = contributors.filter((c) => c.active === '1');
    const inactiveContributors = contributors.filter((c) => c.active === '0');

    return (
      <div>
        <MetaTags title={meta.title} description={meta.description} />
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">What is UnlockedMaps?</h2>
                      <p className="description">
                        UnlockedMaps is a project aimed to map the urban rail
                        transit stations with the primary focus on
                        accessibility. Real-time elevator statuses are
                        displayed, to help assist people who rely on the
                        elevators, such as people who use wheelchairs or have
                        strollers or bikes, plan their commute without
                        inconvenient surprises.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rn-team-area bg_color--1 ptb--120" id="contributors">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-left mb--10">
                  <h2 className="title">Contributors</h2>
                </div>
                <div className="col-md-12 team-container legend">
                  <span>
                    <span className="team R">R</span> Research Team{' '}
                  </span>
                  <span>
                    <span className="team D">D</span> Dev Team
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              {orderBy(activeContributors, ['user_id']).map((contributor) => {
                return (
                  <div
                    className="col-lg-2 col-md-3 col-sm-3 col-6 text-left contributor-card"
                    key={contributor.user_id}
                  >
                    <div
                      className="service service__style--2"
                      style={{
                        backgroundImage: `url(${contributorImageBaseUrl}${
                          images[contributor.user_id]
                        })`,
                        backgroundSize: 'cover',
                      }}
                    >
                      <div className="contributor-card-overlay" />
                      <div className="content">
                        <h4 className="title">
                          {`${
                            contributor.first_name
                          } ${contributor.last_name.substring(0, 1)}.`}
                          <div className="team-container">
                            {contributor.team.split('+').map((t, i) => (
                              <span key={i} className={`team ${t}`}>
                                {t}
                              </span>
                            ))}
                          </div>
                        </h4>
                      </div>
                      <ul className="social-icon">
                        {contributor.github_url && (
                          <li>
                            <a
                              href={contributor.github_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaGithub />
                            </a>
                          </li>
                        )}
                        {contributor.linkedin_url && (
                          <li>
                            <a
                              href={contributor.linkedin_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaLinkedinIn />
                            </a>
                          </li>
                        )}
                        {contributor.twitter_url && (
                          <li>
                            <a
                              href={contributor.twitter_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaTwitter />
                            </a>
                          </li>
                        )}
                        {contributor.personal_website && (
                          <li>
                            <a
                              href={contributor.personal_website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaGlobe />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-md-12">
                <h3 className="title">Past Contributors</h3>
              </div>
              {orderBy(inactiveContributors, ['user_id']).map((contributor) => {
                return (
                  <div
                    className="col-md-3 col-sm-4 col-xs-6 inactive-contributors"
                    key={contributor.user_id}
                  >
                    <span className="contributor-name">{`${
                      contributor.first_name
                    } ${contributor.last_name.substring(0, 1)}.`}</span>
                    <span className="team-container">
                      {contributor.team.split('+').map((t, i) => (
                        <span key={i} className={`team ${t}`}>
                          {t}
                        </span>
                      ))}
                    </span>
                    {contributor.github_url && (
                      <a
                        href={contributor.github_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaGithub />
                      </a>
                    )}
                    {contributor.linkedin_url && (
                      <a
                        href={contributor.linkedin_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaLinkedinIn />
                      </a>
                    )}
                    {contributor.twitter_url && (
                      <a
                        href={contributor.twitter_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    )}
                    {contributor.personal_website && (
                      <a
                        href={contributor.personal_website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaGlobe />
                      </a>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default flowRight(
  withRouter,
  withTitle('About Us'),
  withGoogleSheets('contributors')
)(About);
