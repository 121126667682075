import React from 'react';
import flowRight from 'lodash/flowRight';
import { withSection, withTitle } from '../../components';

const NoMatch = () => (
  <div className="no-match-404">
    <h1 className="title">404</h1>
    <p>Page Not Found</p>
  </div>
);

export default flowRight(
  withTitle('Page Not Found'),
  withSection('404')
)(NoMatch);
