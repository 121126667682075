import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';
import RestaurantPopUp from './RestaurantPopUp';
import { getMarkerIcon } from './helpers';

const RestaurantMarkers = ({ offset, restaurants, zoom }) =>
  restaurants.map((restaurant) => {
    const icon = getMarkerIcon('restaurant', zoom, 'restaurant');
    const { latitude, longitude } = restaurant.coordinates;

    return (
      <Marker position={[latitude, longitude]} key={restaurant.id} icon={icon}>
        <RestaurantPopUp restaurant={restaurant} offset={offset} />
        <Tooltip offset={[(zoom * 1.8) / 2, 0]} direction="right">
          {restaurant.name}
        </Tooltip>
      </Marker>
    );
  });

RestaurantMarkers.propTypes = {
  offset: PropTypes.arrayOf(PropTypes.number),
  restaurants: PropTypes.arrayOf(PropTypes.object),
  zoom: PropTypes.number,
};

export default RestaurantMarkers;
