import React from 'react';
import { Link } from 'react-router-dom';
import flowRight from 'lodash/flowRight';
import { Footer, MetaTags, withTitle } from '../../components';
import cities from '../../profiles';

import './Cities.css';

const meta = {
  description:
    'Explore the accessibility of urban rail transit in a city of your choice.',
  title: 'Cities',
};

const Cities = () => (
  <div>
    <MetaTags title={meta.title} description={meta.description} />
    <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
      <div className="container">
        <h2 className="title">Cities</h2>
        <div className="row creative-service">
          <div className="col-lg-12">
            <div className="row">
              {Object.entries(cities)
                .sort()
                .map(([name, city], index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 col-12 text-left city-card"
                    key={index}
                  >
                    <Link
                      to={`/cities/${city.state
                        .toLowerCase()
                        .replace(' ', '_')}/${name}`}
                    >
                      <div
                        className="service service__style--2"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/images/cities/${name}.jpg)`,
                          backgroundSize: 'cover',
                        }}
                      >
                        <div className="city-card-overlay" />
                        <div className="content">
                          <h3 className="title">{city.name}</h3>
                          <p>{city.state}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default flowRight(withTitle('Cities'))(Cities);
