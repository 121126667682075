import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { withRouter } from 'react-router-dom';
import flowRight from 'lodash/flowRight';
import uniq from 'lodash/uniq';
import querystring from 'querystring';
import Spinner from 'react-spinkit';
import startCase from 'lodash/startCase';
import Map from './Map';
import MapSidebar from './MapSidebar';
import FilterRow from './FilterRow';
import Legend from './Legend';
import {
  MetaTags,
  withLoading,
  withSection,
  withStationsData,
  withTitle,
} from '../../components';
import { filterData, getCenter } from './helpers';

import './index.css';

class City extends Component {
  static propTypes = {
    allData: PropTypes.arrayOf(PropTypes.object),
    defaultCenter: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    defaultZoom: PropTypes.number,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        stationId: PropTypes.string,
        stationName: PropTypes.string,
      }),
    }),
    data: PropTypes.arrayOf(PropTypes.object),
  };

  static contextTypes = {
    city: PropTypes.string,
    setCityName: PropTypes.func,
  };

  defaultSliderValue = 0.5;

  state = {
    allData: this.props.allData,
    allLines: uniq(this.props.allData.map((d) => d.name)),
    restaurants: null,
    restrooms: null,
    restaurantDistance: this.defaultSliderValue,
  };

  constructor(props) {
    super(props);
    const filteredData = filterData(props.data, props.location);
    const { latitude, longitude } = getCenter(
      filteredData,
      this.state.allData,
      props.defaultCenter
    );
    this.state = {
      ...this.state,
      data: filteredData,
      isStationSelected: false,
      latitude,
      longitude,
      refetchRestaurants: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { data, defaultCenter, location, match } = props;
    const { allData, restaurants, restrooms, selectedStation } = state;

    const filteredData = filterData(data, location);

    const { latitude, longitude } = getCenter(
      filteredData,
      allData,
      defaultCenter
    );

    const wasStationUnselected =
      selectedStation &&
      match.params.stationName == null &&
      match.params.stationId == null;

    const newSelectedStation =
      match.params.stationName != null && match.params.stationId != null
        ? filteredData[0].stations[0]
        : null;

    return {
      data: filteredData,
      latitude,
      longitude,
      restaurants: newSelectedStation ? restaurants : null,
      restrooms: newSelectedStation ? restrooms : null,
      selectedStation: newSelectedStation,
      wasStationUnselected,
    };
  }

  componentDidUpdate() {
    this.fetchAdditionalData();
  }

  componentDidMount() {
    this.context.setCityName(startCase(this.props.match.params.city));
    this.fetchAdditionalData();
  }

  componentWillUnmount() {
    this.context.setCityName(null);
  }

  fetchAdditionalData = async () => {
    const {
      expanded,
      loading,
      refetchRestaurants,
      restaurantDistance,
      restaurants,
      restrooms,
      selectedStation,
    } = this.state;

    const searchFieldContainer = document.getElementById('root_keywords');

    if (searchFieldContainer) {
      searchFieldContainer.getElementsByTagName('input')[0].focus();
    }

    this.toggleZoomControlsPosition(
      selectedStation || (!selectedStation && expanded)
    );

    if (
      refetchRestaurants ||
      (selectedStation && !restaurants && !restrooms && !loading)
    ) {
      this.setState({ loading: true, refetchRestaurants: false });

      const restaurants = await this.getRestaurants({
        ...selectedStation,
        restaurantDistance,
      });
      const restrooms = await this.getRestrooms(selectedStation);

      this.setState({
        loading: false,
        refetchRestaurants: false,
        restaurants,
        restrooms,
      });
    } else if (!selectedStation && (restaurants || restrooms)) {
      this.setState({ restaurants: null, restrooms: null });
    }
  };

  updateRestaurantDistance = (restaurantDistance) => {
    if (this.state.restaurantDistance !== restaurantDistance) {
      this.setState(
        {
          refetchRestaurants: true,
          restaurantDistance,
        },
        this.fetchAdditionalData
      );
    }
  };

  getRestaurants = async (station) => {
    let restaurants = await fetch(
      `${process.env.REACT_APP_SERVER}/restaurants`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(station),
        method: 'POST',
      }
    );

    restaurants = await restaurants.json();

    return restaurants ? restaurants.data : [];
  };

  getRestrooms = async (station) => {
    let restrooms = await fetch(`${process.env.REACT_APP_SERVER}/restrooms`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(station),
      method: 'POST',
    });

    restrooms = await restrooms.json();

    return restrooms ? restrooms.data : [];
  };

  getParams = () => {
    const search = querystring.parse(this.props.location.search.substring(1));

    return {
      features: search.features ? search.features.split(',') : [],
      keywords: search.keywords || '',
      transitAuthorities: search.transitAuthorities
        ? search.transitAuthorities.split(',')
        : this.state.allLines,
    };
  };

  toggleZoomControlsPosition = (expanded = false) => {
    const zoomControls = document.getElementsByClassName('leaflet-left')[0];

    if (zoomControls) {
      zoomControls.style.left = expanded ? '300px' : 0;
    }
  };

  toggleSidebar = () => {
    const { expanded, selectedStation } = this.state;

    this.setState({ expanded: !expanded }, () => {
      this.toggleZoomControlsPosition(
        selectedStation || (!selectedStation && this.state.expanded)
      );
    });
  };

  getMetadata = (selectedStation) => {
    const { city, state } = this.props.match.params;

    let title = startCase(city) + ', ' + startCase(state);
    let description =
      'Explore the accessibility of urban rail transit in ' + title;

    if (selectedStation) {
      title = selectedStation.name + ' in ' + title;
      description =
        'Explore the elevator outages in the past 30 days, and accessible restaurants and restrooms near ' +
        title;
    }

    return { description, title };
  };

  render() {
    const {
      allData,
      allLines,
      data,
      expanded,
      latitude,
      loading,
      longitude,
      restaurantDistance,
      restaurants,
      restrooms,
      selectedStation,
      wasStationUnselected,
    } = this.state;

    const { defaultZoom, match } = this.props;
    const { city } = match.params;
    const {
      features,
      keywords,
      transitAuthorities: filteredTransitAuthorities,
    } = this.getParams();
    const allTransitAuthorities = allData.map((o) => ({
      name: o.name,
      organization: o.organization,
    }));

    let expandedToggleButtonText = 'Hide Transit Authorities';
    let collapsedToggleButtonText = 'Show Transit Authorities';

    if (selectedStation) {
      expandedToggleButtonText = 'Hide Station Details';
      collapsedToggleButtonText = 'Show Station Details';
    }

    const shouldShowSidebar = selectedStation || (!selectedStation && expanded);
    const meta = this.getMetadata(selectedStation);

    return loading ? (
      <div>
        <div className="spinner-overlay" />
        <Spinner name="line-scale-pulse-out-rapid" color="#0074d9" />
      </div>
    ) : (
      <div
        className={`map-container${selectedStation ? ' station-selected' : ''}`}
      >
        <MetaTags title={meta.title} description={meta.description} />
        {shouldShowSidebar && (
          <Col md={3} xs={12} className="map-sidebar">
            <MapSidebar
              allTransitAuthorities={allTransitAuthorities}
              city={city}
              data={data}
              expanded={expanded}
              features={features}
              filteredTransitAuthorities={filteredTransitAuthorities}
              keywords={keywords}
              lines={allLines}
              restaurantDistance={restaurantDistance}
              restaurants={restaurants}
              station={selectedStation}
              updateRestaurantDistance={this.updateRestaurantDistance}
              {...this.props}
            />
          </Col>
        )}
        <Col md={12} xs={12}>
          <Map
            data={data}
            defaultZoom={defaultZoom}
            latitude={latitude}
            longitude={longitude}
            restaurants={restaurants}
            restrooms={restrooms}
            showPopUp={selectedStation == null}
            wasStationUnselected={wasStationUnselected}
          />
          {!selectedStation && (
            <FilterRow
              lines={allLines}
              features={features}
              keywords={keywords}
              transitAuthorities={filteredTransitAuthorities}
              {...this.props}
            />
          )}
          {!selectedStation && (
            <div
              className="map-sidebar-toggle-button"
              onClick={this.toggleSidebar}
              style={{
                left: expanded ? '300px' : 0,
              }}
            >
              <span>
                <i
                  className={`fa fa-angle-double-${
                    expanded ? 'left' : 'right'
                  }`}
                />
              </span>
              {expanded ? expandedToggleButtonText : collapsedToggleButtonText}
            </div>
          )}
          <Legend />
        </Col>
      </div>
    );
  }
}

export default flowRight(
  withTitle({ path: 'match.params.city' }),
  withSection('city'),
  withRouter,
  withStationsData(),
  withLoading()
)(City);
