import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import compact from 'lodash/compact';
import find from 'lodash/find';
import { NoMatch } from '../../views';
import profiles from '../../profiles';

const withStationsData = () => (WrappedComponent) =>
  class extends Component {
    static displayName = 'WrappedComponent';

    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          city: PropTypes.string.isRequired,
          state: PropTypes.string.isRequired,
          stationId: PropTypes.string,
          stationLine: PropTypes.string,
          stationName: PropTypes.string,
        }),
      }),
    };

    state = {
      loading: true,
      data: null,
    };

    componentDidMount() {
      const { city, state } = this.props.match.params;

      fetch(`${process.env.REACT_APP_SERVER}/stations`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ city, state }),
        method: 'POST',
      })
        .then((stations) => stations.json())
        .then((response) =>
          this.setState({ loading: false, data: response.data })
        )
        .catch((e) => console.log(e));
    }

    render() {
      let { data, loading } = this.state;
      let {
        city,
        stationLine,
        stationName,
        stationId,
      } = this.props.match.params;
      const profile = profiles[city];
      const allData = data;
      const defaultZoom =
        profile && profile.defaultZoom ? profile.defaultZoom : 12;

      if (!loading && stationName && stationId) {
        stationLine = decodeURIComponent(stationLine);
        stationName = decodeURIComponent(stationName);
        stationId = decodeURIComponent(stationId);

        data = compact(
          data.map((d) => {
            let stationData = find(
              d.stations,
              (s) =>
                d.name.toLowerCase() === stationLine &&
                s.id === stationId &&
                s.name.toLowerCase() === stationName
            );

            return stationData
              ? [
                  {
                    ...d,
                    stations: [
                      {
                        ...stationData,
                        latitude: parseFloat(stationData.latitude),
                        longitude: parseFloat(stationData.longitude),
                      },
                    ],
                  },
                ]
              : null;
          })
        )[0];
      }

      if (loading) {
        return (
          <div className="city-loading">
            <div className="spinner-overlay" />
            <Spinner name="line-scale-pulse-out-rapid" color="#0074d9" />
          </div>
        );
      } else if (!loading && data) {
        return (
          <WrappedComponent
            allData={allData}
            data={data}
            defaultCenter={profile.defaultCenter}
            defaultZoom={defaultZoom}
            {...this.props}
          />
        );
      } else {
        return <NoMatch />;
      }
    }
  };

export default withStationsData;
