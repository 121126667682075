import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import uniqBy from 'lodash/uniqBy';
import startCase from 'lodash/startCase';
import { IconTooltip } from '../../../components';

import './TransitAuthorities.css';

const TooltipContent = ({ authority }) => (
  <div>
    <p>{authority.info}</p>
    <a
      href={authority.website}
      className="btn btn-primary"
      rel="noopener noreferrer"
      target="_blank"
    >
      Visit Website
    </a>
  </div>
);

TooltipContent.propTypes = {
  authority: PropTypes.shape({
    info: PropTypes.string,
    website: PropTypes.string,
  }),
};

const TransitAuthorities = ({
  allTransitAuthorities,
  filteredTransitAuthorities,
  onCheck,
  toggleAll,
}) => {
  const allAuthorities = uniqBy(
    allTransitAuthorities.map((o) => o.organization),
    'name'
  );

  return (
    <div className="transit-authorities">
      <h2>Transit Authorities</h2>
      {allAuthorities.map((authority) => {
        const lines = allTransitAuthorities
          .filter((a) => a.organization.name === authority.name)
          .map((a) => a.name);
        const allSelected = lines.every((l) =>
          filteredTransitAuthorities.includes(l)
        );

        return (
          <div key={authority.name}>
            <h3>
              {authority.name}
              <IconTooltip
                icon="info"
                className="transit-info-tooltip"
                authority={authority}
                Content={TooltipContent}
              />
            </h3>
            <div className="check-all">
              <button
                className="check-uncheck"
                disabled={
                  allSelected &&
                  lines.length === filteredTransitAuthorities.length
                }
                onClick={() =>
                  toggleAll(authority.name, allSelected ? 'unselect' : 'select')
                }
              >
                {allSelected ? 'Uncheck All' : 'Select All'}
              </button>
            </div>
            {lines.map((l) => {
              const checked = filteredTransitAuthorities.includes(l);
              const disabled =
                checked && filteredTransitAuthorities.length === 1;

              return (
                <FormControlLabel
                  key={l}
                  control={
                    <Checkbox
                      value={l}
                      disabled={disabled}
                      checked={checked}
                      color="primary"
                      onChange={(e) => onCheck(e.target)}
                    />
                  }
                  label={startCase(l)}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

TransitAuthorities.propTypes = {
  allTransitAuthorities: PropTypes.arrayOf(PropTypes.object),
  filteredTransitAuthorities: PropTypes.arrayOf(PropTypes.string),
  onCheck: PropTypes.func,
  toggleAll: PropTypes.func,
};

export default TransitAuthorities;
