import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import GoogleSheetsProvider from 'react-db-google-sheets';
import ScrollToTop from 'react-scroll-up';
import { FiArrowUp } from 'react-icons/fi';
import ReactGA from 'react-ga4';
import FeedbackModal from './FeedbackModal';
import Routes from './Routes';
import { NavBar } from './components';

import './App.scss';

const graphqlUrl = `${process.env.REACT_APP_SERVER}${process.env.REACT_APP_GRAPHQL_URL}`;

const client = new ApolloClient({
  link: new HttpLink({ uri: graphqlUrl }),
  cache: new InMemoryCache(),
});

class App extends Component {
  static childContextTypes = {
    city: PropTypes.string,
    setCityName: PropTypes.func,
  };

  state = {
    city: null,
    showFeedbackModal: false,
  };

  componentDidMount() {
    ReactGA.initialize('G-QMNW5SM388');
  }

  setCityName = (city) => this.setState({ city });

  toggleFeedbackModal = (showFeedbackModal) =>
    this.setState({ showFeedbackModal });

  getChildContext() {
    const { city } = this.state;

    return { city, setCityName: this.setCityName };
  }

  render() {
    const { city, showFeedbackModal } = this.state;

    return (
      <ApolloProvider client={client}>
        <GoogleSheetsProvider>
          <Router>
            <div className="main-content">
              <NavBar
                city={city}
                toggleFeedbackModal={this.toggleFeedbackModal}
              />
              <Routes />
              <FeedbackModal
                toggleFeedbackModal={this.toggleFeedbackModal}
                showFeedbackModal={showFeedbackModal}
              />
              <div className="backto-top">
                <ScrollToTop showUnder={160}>
                  <FiArrowUp />
                </ScrollToTop>
              </div>
            </div>
          </Router>
        </GoogleSheetsProvider>
      </ApolloProvider>
    );
  }
}

export default App;
