import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Fuse from 'fuse.js';
import querystring from 'querystring';
import isEqual from 'lodash/isEqual';
import { withRouter } from 'react-router-dom';
import { Form } from '../../../components';

import './index.css';

const getStationsList = (data) =>
  data
    .map((d) =>
      d.stations.map((s) => ({
        label: s.name,
        line: s.line,
        organization: d.organization.name,
        station: s.name,
        stationId: s.id,
        value: s.name,
      }))
    )
    .flat();

const customSelectFilter = ({ data }, keywords) => {
  if (!keywords || keywords === '') return true;

  const result = new Fuse([data.station, data.line, data.organization], {
    threshold: 0.25,
  }).search(keywords);

  return result.length > 0;
};

const Option = ({ data, innerProps, innerRef }) => (
  <div className="select-station-option" ref={innerRef} {...innerProps}>
    <div className="station-name">{data.station}</div>
    <div className="line-organization-name">{`${data.line} - ${data.organization}`}</div>
  </div>
);

Option.propTypes = {
  data: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
};

const schema = {
  type: 'object',
  properties: {
    keywords: {
      type: 'string',
      title: 'Keywords',
    },
    features: {
      type: 'array',
      title: 'Features',
      items: {
        type: 'string',
        enum: ['wheelchair_accessible', 'bike_rack', 'parking'],
        enumNames: [
          'Wheelchair Accessible',
          'Bike Rack Available',
          'Parking Available',
        ],
      },
      uniqueItems: true,
    },
  },
};

class FilterRow extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    features: PropTypes.arrayOf(PropTypes.string),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    keywords: PropTypes.string,
    lines: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    transitAuthorities: PropTypes.arrayOf(PropTypes.string),
  };

  uiSchema = (stations) => ({
    keywords: {
      'ui:placeholder': 'Search by Station Name',
      classNames: 'hide-label hide-title keywords-widget',
      'ui:widget': (props) => (
        <Select
          id={props.id}
          components={{ Option }}
          filterOption={customSelectFilter}
          value={
            props.value !== ''
              ? { label: props.value, value: props.value }
              : null
          }
          onChange={(option) => {
            this.navigateToStationPage(option);
            props.onChange(option ? option.value : null);
          }}
          options={stations}
          isClearable={true}
          isSearchable={true}
          placeholder="Search or Select Station..."
        />
      ),
    },
    features: {
      'ui:widget': 'CheckboxWidget',
      'ui:labelIcons': ['wheelchair-alt', 'bicycle', 'car'],
      classNames: 'hide-label hide-title',
    },
  });

  onChange = (formProps) => {
    const { history, location, transitAuthorities } = this.props;

    let { features, keywords } =
      formProps && formProps.formData ? formProps.formData : this.props;

    keywords = keywords || '';
    features = features || [];

    const search = querystring.parse(location.search.substring(1));
    const searchFeatures = search.features ? search.features.split(',') : [];
    const areFeaturesSame = isEqual(features, searchFeatures);

    if (!areFeaturesSame) {
      history.push({
        ...location,
        search: `keywords=${keywords}&features=${features.join(
          ','
        )}&transitAuthorities=${transitAuthorities}`,
      });
    }
  };

  navigateToStationPage = (option) => {
    if (option) {
      const {
        history,
        match: { params },
      } = this.props;
      const { line, station, stationId } = option;
      const { city, state } = params;

      const url = `/cities/${state}/${city}/station/${encodeURIComponent(
        line.toLowerCase()
      )}/${encodeURIComponent(station.toLowerCase())}/${stationId}`;

      history.push(url);
    }
  };

  render() {
    const { data, features, keywords } = this.props;
    const formData = { features, keywords };
    const stations = getStationsList(data);

    return (
      <div className="filter-row">
        <Form
          formData={formData}
          schema={schema}
          uiSchema={this.uiSchema(stations)}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default withRouter(FilterRow);
