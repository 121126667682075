import React from 'react';
import { Switch, Route } from 'react-router';
import ReactGA from 'react-ga4';
import { About, Cities, City, Contact, Home, NoMatch } from './views';

const componentWithGA = (Component, props, fullWidth = false) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
    title: 'UnlockedMaps',
  });

  return <Component fullWidth={fullWidth} {...props} />;
};

const Routes = () => (
  <Switch>
    <Route exact path="/" render={() => componentWithGA(Home)} />
    <Route exact path="/about" render={() => componentWithGA(About)} />
    <Route exact path="/cities" render={() => componentWithGA(Cities)} />
    <Route exact path="/contact" render={() => componentWithGA(Contact)} />
    <Route exact path="/home" render={() => componentWithGA(Home)} />
    <Route
      exact
      path="/cities/:state/:city"
      render={(props) => componentWithGA(City, props, true)}
    />
    <Route
      exact
      path="/cities/:state/:city/station/:stationLine/:stationName/:stationId"
      render={(props) => componentWithGA(City, props, true)}
    />
    <Route render={() => componentWithGA(NoMatch)} />
  </Switch>
);

export default Routes;
