import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const Banner = ({ children, city }) => {
  const cityImage = `${process.env.PUBLIC_URL}/images/cities/${city}.jpg`;

  return (
    <div className="banner">
      <div
        className="container"
        style={{
          backgroundImage: `url(${cityImage})`,
          backgroundPositionY: '50%',
        }}
      >
        <div className="contents">{children}</div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node,
  city: PropTypes.string.isRequired,
};

export default Banner;
