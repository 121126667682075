import React from 'react';

import './index.css';

const Legend = () => (
  <div className="legend">
    <div className="row">
      <img
        src={require('../../../components/CustomMap/icons/subway.svg')}
        className="legend-icon-svg"
        alt="Subway Icon"
      />
      Subway
    </div>
    <div className="row">
      <img
        src={require('../../../components/CustomMap/icons/train.svg')}
        className="legend-icon-svg"
        alt="Train Icon"
      />
      Train
    </div>
    <div className="row">
      <img
        src={require('../../../components/CustomMap/icons/trolley.svg')}
        className="legend-icon-svg"
        alt="Trolley Icon"
      />
      Trolley
    </div>

    <div className="row">
      <i className="fa fa-circle accessible" />
      Accessible Station
    </div>
    <div className="row">
      <i className="fa fa-circle outage" />
      Elevator Outage Reported
    </div>
    <div className="row">
      <i className="fa fa-circle not-accessible" />
      Not Accessible
    </div>
  </div>
);

export default Legend;
