import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import StationInfo from './StationInfo';
import TransitAuthorities from './TransitAuthorities';
import Restaurants from '../Restaurants';

import './index.css';

class MapSidebar extends Component {
  static propTypes = {
    allTransitAuthorities: PropTypes.arrayOf(PropTypes.object),
    city: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    features: PropTypes.arrayOf(PropTypes.string),
    filteredTransitAuthorities: PropTypes.arrayOf(PropTypes.string),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    keywords: PropTypes.string,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    restaurantDistance: PropTypes.number,
    restaurants: PropTypes.arrayOf(PropTypes.object),
    station: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    updateRestaurantDistance: PropTypes.func,
  };

  onCheck = (input) => {
    let { filteredTransitAuthorities: transitAuthorities } = this.props;

    if (input.checked) {
      transitAuthorities = [...transitAuthorities, input.value];
    } else {
      transitAuthorities = transitAuthorities.filter((d) => d !== input.value);
    }

    this.updateUrl(transitAuthorities);
  };

  updateUrl = (transitAuthorities) => {
    const { features, history, keywords, location } = this.props;

    history.push({
      ...location,
      search: `keywords=${keywords}&features=${features}&transitAuthorities=${transitAuthorities.join(
        ','
      )}`,
    });
  };

  toggleAll = (transitAuthority, action) => {
    let { data, filteredTransitAuthorities: transitAuthorities } = this.props;

    const lines = data
      .filter((d) => d.organization.name === transitAuthority)
      .map((d) => d.name);

    if (action === 'select') {
      transitAuthorities = uniq([...transitAuthorities, ...lines]);
    } else if (action === 'unselect') {
      transitAuthorities = transitAuthorities.filter((t) => !lines.includes(t));
    }

    this.updateUrl(transitAuthorities);
  };

  render() {
    const {
      allTransitAuthorities,
      city,
      data,
      filteredTransitAuthorities,
      restaurantDistance,
      restaurants,
      station,
      updateRestaurantDistance,
    } = this.props;

    return (
      <div>
        {station ? (
          <StationInfo
            authority={data[0].organization.name}
            city={city}
            station={station}
          />
        ) : (
          <TransitAuthorities
            allTransitAuthorities={allTransitAuthorities}
            filteredTransitAuthorities={filteredTransitAuthorities}
            onCheck={this.onCheck}
            toggleAll={this.toggleAll}
          />
        )}
        {station && restaurants && (
          <Restaurants
            station={station.name}
            restaurantDistance={restaurantDistance}
            restaurants={restaurants}
            updateRestaurantDistance={updateRestaurantDistance}
          />
        )}
      </div>
    );
  }
}

export default MapSidebar;
