import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';

const withLoading = () => (WrappedComponent) =>
  class extends Component {
    static displayName = 'WrappedComponent';

    static propTypes = {
      data: PropTypes.any,
      loading: PropTypes.bool,
    };

    render() {
      const { data, loading } = this.props;
      const dataLoading = data ? data.loading : false;

      return !loading && !dataLoading ? (
        <WrappedComponent {...this.props} />
      ) : (
        <div>
          <div className="spinner-overlay" />
          <Spinner name="line-scale-pulse-out-rapid" color="#0074d9" />
        </div>
      );
    }
  };

export default withLoading;
