import React from 'react';
import flowRight from 'lodash/flowRight';
import { Link } from 'react-router-dom';
import { MetaTags, withSection, withTitle } from '../../components';

import './Contact.css';

const meta = {
  description:
    'Contact us with questions, feedback, and inquiries about joining the team.',
  title: 'Contact Us',
};

const Contact = () => (
  <div className="contact-us ptb--60">
    <MetaTags title={meta.title} description={meta.description} />
    <h1 className="title">Contact Us</h1>
    <p>
      {' '}
      UnlockedMaps would not be possible without the tireless efforts from our
      volunteer <Link to="/about">collaborators</Link>. It is home to
      researchers who are interested in conducting research on urban rail
      transit systems, civic hackers who are looking for contributing to this
      project through their dev and design skills, students who are seeking to
      work on a multi-city accessibility-focused project, advocates who are
      continuously conducting efforts to induce change, and anyone who would
      like to be involved in whatever capacity they&apos;re comfortable with.
    </p>
    <p>
      If you&apos;re interested in collaborating, please email{' '}
      <a
        href="mailto:ather.sharif@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        ather dot sharif at gmail dot com
      </a>
      .
    </p>
  </div>
);

export default flowRight(
  withTitle('Contact Us'),
  withSection('contact')
)(Contact);
