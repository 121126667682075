import PropTypes from 'prop-types';
import querystring from 'querystring';
import Fuse from 'fuse.js';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import flatMap from 'lodash/flatMap';
import sum from 'lodash/sum';

const getFuzzyOptions = (keys) => ({
  threshold: 0.25,
  keys,
});

const getMedian = (data) => {
  data = data.sort();

  return (data[(data.length - 1) >> 1] + data[data.length >> 1]) / 2;
};

const filterByKeywords = (data, keywords) =>
  data && keywords && keywords !== ''
    ? data.map((d) => ({
        ...d,
        stations: new Fuse(d.stations, getFuzzyOptions(['name']))
          .search(keywords)
          .map((i) => i.item),
      }))
    : data;

const filterByTransitAuthorities = (data, transitAuthorities) => {
  if (data && transitAuthorities && transitAuthorities !== '') {
    transitAuthorities = compact(transitAuthorities.split(','));

    return data.filter((d) => transitAuthorities.includes(d.name));
  } else {
    return data;
  }
};

const filterByFeatures = (data, features) => {
  if (data && features && features !== '') {
    features = compact(features.split(','));

    data = data.map((d) => ({
      ...d,
      stations: filter(d.stations, (s) =>
        reduce(features, (result, f) => result && s[f], true)
      ),
    }));
  }

  return data;
};

export const filterData = (data, location) => {
  const search = querystring.parse(location.search.substring(1));

  data = filterByKeywords(data, search.keywords);
  data = filterByTransitAuthorities(data, search.transitAuthorities);
  data = filterByFeatures(data, search.features);

  return data;
};

filterData.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export const getTotalStations = (data) => {
  let total = 0;

  data.forEach((d) => (total += d.stations.length));

  return total;
};

export const getCenter = (
  filteredData = [],
  originalData = [],
  defaultCenter
) => {
  const totalFilteredStations = flatMap(filteredData.map((f) => f.stations))
    .length;
  const totalOriginalStations = flatMap(originalData.map((o) => o.stations))
    .length;

  if (totalFilteredStations === totalOriginalStations && defaultCenter) {
    return defaultCenter;
  }

  let data =
    filteredData &&
    filteredData.length > 0 &&
    sum(filteredData.map((d) => d.stations.length)) > 0
      ? filteredData
      : originalData;

  data = flatMap(data, (d) =>
    d.stations.map((s) => ({
      latitude: parseFloat(s.latitude),
      longitude: parseFloat(s.longitude),
    }))
  );

  return {
    latitude: getMedian(data.map((d) => d.latitude)),
    longitude: getMedian(data.map((d) => d.longitude)),
  };
};

getCenter.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

export const computeState = (
  stationName,
  restaurants,
  restrooms,
  latitude,
  longitude,
  zoom,
  stationLine
) => ({
  stationName,
  restaurants,
  restrooms,
  latitude,
  longitude,
  zoom,
  stationLine,
});

computeState.propTypes = {
  stationName: PropTypes.string,
  restaurants: PropTypes.arrayOf(PropTypes.object),
  restrooms: PropTypes.arrayOf(PropTypes.object),
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
  stationLine: PropTypes.string,
};

export const doStationsMatch = (longerName, shorterName) => {
  const longerNames = longerName.split(' ');
  const shorterNames = shorterName.split(' ');

  let matches = 0;

  shorterNames.forEach((sn) => {
    if (longerNames.includes(sn)) {
      matches++;
    }
  });

  return matches === shorterNames.length;
};

doStationsMatch.propTypes = {
  longerName: PropTypes.string,
  shorterName: PropTypes.string,
};
