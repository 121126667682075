import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link, withRouter } from 'react-router-dom';
import flowRight from 'lodash/flowRight';
import uniq from 'lodash/uniq';
import Calendar from 'react-calendar';
import Banner from '../Banner';

import 'react-calendar/dist/Calendar.css';
import './StationInfo.css';

const today = new Date();
const defaultActiveStartDate = new Date(
  new Date().setDate(today.getDate() - 30)
);

const getMinDate = () => {
  let month = today.getMonth() - 1;
  let year = today.getFullYear();

  if (month === -1) {
    month = 11;
    year = year - 1;
  }

  return new Date(year, month, 1);
};

const getMaxDate = () => today;

const disableDates = ({ date }) => {
  return (
    date.getTime() < defaultActiveStartDate.getTime() - 8.64e7 || date > today
  );
};

const renderNavigationLabel = ({ date }) =>
  new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date) +
  ' ' +
  date.getFullYear();

const formatDates = ({ date }, outageDates) => {
  date =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2);

  if (outageDates.includes(date)) {
    return 'date-with-elevator-outage';
  }

  return 'calendar-date';
};

const StationInfo = ({
  authority,
  city,
  elevatorOutages,
  match: { params },
  station,
}) => {
  const allOutages = elevatorOutages.allElevatorOutages || { nodes: [] };

  const filteredElevatorOutages = allOutages.nodes
    .filter((s) => s.station === station.name)
    .map((s) => ({
      line: s.line,
      outageDate: s.lastUpdatedAt,
      parsed: s.parsed,
      station: s.station,
    }));

  const elevatorOutagesDates = uniq(
    filteredElevatorOutages.map((o) => o.outageDate.split(' ')[0])
  );
  const hasCurrentOutage = filteredElevatorOutages.some((o) => !o.parsed);

  const isBikeRackAvailable = station.bike_rack;
  const isParkingAvailable = station.parking;
  const isAccessible = station.wheelchair_accessible;
  const isElevatorOut = isAccessible && hasCurrentOutage;

  let elevatorInfoClass = isAccessible ? 'accessible' : 'not-accessible';

  if (isElevatorOut) {
    elevatorInfoClass = 'elevator-out';
  }

  return (
    <div className="station-info">
      <Link
        to={`/cities/${params.state}/${params.city}`}
        className="back-to-ta-link"
      >
        <i className="fa fa-arrow-left" />
        Back to Main Map
      </Link>
      <Banner city={city}>
        <h2 className="city-title">{station.name}</h2>
        <div className="status-icons">
          {isAccessible && <i className="fa fa-wheelchair-alt" />}
          {isBikeRackAvailable && <i className="fa fa-bicycle" />}
          {isParkingAvailable && <i className="fa fa-car" />}
        </div>
        <div className="authority-info">Operated by {authority}</div>
      </Banner>
      <div className={`elevator-info ${elevatorInfoClass}`}>
        {isElevatorOut && (
          <div>
            <i className="fa fa-warning" />
            Elevator Outage Reported
          </div>
        )}
        {!isElevatorOut && isAccessible && (
          <div>
            <i className="fa fa-check" />
            Station Accessible &amp; No Outage Reported Currently
          </div>
        )}
        {!isAccessible && (
          <div>
            <i className="fa fa-ban" />
            Station Not Accessible
          </div>
        )}
      </div>
      {isAccessible && (
        <div className="calendar-title">
          Outages this month and the past month
          <span className="helper-text">
            *outages for a given date are indicated in{' '}
            <span className="outage-example">red</span>
          </span>
        </div>
      )}
      {isAccessible && (
        <Calendar
          navigationLabel={renderNavigationLabel}
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          next2Label={null}
          prev2Label={null}
          showFixedNumberOfWeeks={true}
          tileClassName={(obj) => formatDates(obj, elevatorOutagesDates)}
          tileDisabled={disableDates}
        />
      )}
    </div>
  );
};

const elevatorOutagesQuery = gql`
  query($city: String, $dateBegin: String, $stationName: String) {
    allElevatorOutages(
      filter: {
        city: { equalTo: $city }
        lastUpdatedAt: { greaterThanOrEqualTo: $dateBegin }
        station: { equalTo: $stationName }
      }
    ) {
      nodes {
        line
        elevator
        station
        message
        city
        transitAuthority
        id
        lastUpdatedAt
        parsed
      }
    }
  }
`;

StationInfo.propTypes = {
  authority: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  elevatorOutages: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
    }),
  }),
  station: PropTypes.object.isRequired,
};

export default flowRight(
  withRouter,
  graphql(elevatorOutagesQuery, {
    name: 'elevatorOutages',
    options: (props) => ({
      variables: {
        city: props.match.params.city,
        dateBegin: defaultActiveStartDate,
        stationName: props.station.name,
      },
    }),
    fetchPolicy: 'network-only',
  })
)(StationInfo);
