import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import round from 'lodash/round';
import orderBy from 'lodash/orderBy';
import Slider from '@material-ui/core/Slider';

import './index.css';

const getSliderMarks = (min, max, step = 0.5) => {
  const marks = [];

  while (min <= max) {
    marks.push({
      label: min.toString() + ' mi',
      value: min,
    });

    min = min + step;
  }

  return marks;
};

class Restaurants extends Component {
  static propTypes = {
    station: PropTypes.string,
    restaurantDistance: PropTypes.number,
    restaurants: PropTypes.arrayOf(PropTypes.object),
    updateRestaurantDistance: PropTypes.func,
  };

  sliderOnChange = (_, restaurantDistance) => {
    this.props.updateRestaurantDistance(restaurantDistance);
  };

  render() {
    const step = 0.5;
    const marks = getSliderMarks(0.5, 2, step);
    const { restaurantDistance } = this.props;

    return (
      <div className="restaurants">
        <h3>
          Food Nearby
          <div>All locations displayed are wheelchair accessible</div>
        </h3>
        <div className="restaurant-slider-container">
          <span className="restaurant-slider-title">Distance</span>
          <span className="restaurant-slider">
            <Slider
              value={restaurantDistance}
              getAriaValueText={(value) => value}
              valueLabelDisplay="auto"
              step={null}
              marks={marks}
              min={0.5}
              max={2}
              onChangeCommitted={this.sliderOnChange}
            />
          </span>
        </div>
        {orderBy(this.props.restaurants, 'distance').map((r) => (
          <Row key={r.id} className="restaurant">
            <Col md={8} xs={8}>
              <h4>
                <span className="restaurant-name">{r.name}</span>
                <div className="restaurant-link">
                  <a href={r.url} target="_blank" rel="noopener noreferrer">
                    View on Yelp
                  </a>
                  <span className="restaurant-distance">
                    {round(r.distance, 2)} mi
                  </span>
                </div>
              </h4>
            </Col>
            <Col md={4} xs={4}>
              <div
                className="restaurant-image"
                style={{
                  backgroundImage: `url(${r.image_url.replace(
                    'o.jpg',
                    'ms.jpg'
                  )})`,
                }}
              />
            </Col>
          </Row>
        ))}
      </div>
    );
  }
}

export default Restaurants;
