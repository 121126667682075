import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';

import logo from '../Navigation/logo.png';

const Footer = () => (
  <div
    className="footer-style-2 ptb--30 bg_image bg_image--1 footer"
    data-black-overlay="6"
  >
    <div className="wrapper plr--50 plr_sm--20">
      <div className="top-footer row">
        <div className="col-lg-9 col-md-12">
          <h1>Join Us!</h1>
          <p>
            Explore urban rail transit with accessibility in mind. View
            real-time elevator status to plan your commute.
          </p>
        </div>
        <div className="col-lg-3 col-md-12">
          <div className="header-btn text-right">
            <Link className="rn-btn" to="/contact">
              <span>Contact Us</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="row bottom-footer align-items-center justify-content-between">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="inner">
            <div className="logo text-left mb_sm--20">
              <Link to="/home">
                <img src={logo} alt="Logo images" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
