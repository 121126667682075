import React from 'react';
import PropTypes from 'prop-types';
import { Helmet as M } from 'react-helmet';

const MetaTags = ({
  description = '',
  image = 'https://unlockedmaps.com/images/main-image.jpg',
  title = '',
}) => (
  <M>
    <meta name="description" content={description} />

    <meta property="og:url" content={window.location} />
    <meta property="og:title" content={'UnlockedMaps - ' + title} />
    <meta property="og:image" content={image} />
    <meta property="og:description" content={description} />

    <meta name="twitter:title" content={'UnlockedMaps - ' + title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
  </M>
);

MetaTags.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
};

export default MetaTags;
