import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const IconTooltip = ({ icon, Content, className, ...rest }) => (
  <Tooltip
    title={<Content {...rest} />}
    arrow={true}
    placement="right-start"
    classes={{ tooltipPlacementRight: className }}
    interactive={true}
  >
    <i className={`fa fa-${icon}`} />
  </Tooltip>
);

IconTooltip.propTypes = {
  Content: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default IconTooltip;
