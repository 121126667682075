import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';

import './RestaurantPopUp.css';

const RestaurantPopUp = ({ restaurant, offset }) => (
  <Popup className="restaurant-pop-up" offset={offset}>
    <div>
      <h4>{restaurant.name}</h4>
      <a
        className="btn bt-primary"
        href={restaurant.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        View on Yelp
      </a>
    </div>
  </Popup>
);

RestaurantPopUp.propTypes = {
  offset: PropTypes.arrayOf(PropTypes.number),
  restaurant: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default RestaurantPopUp;
