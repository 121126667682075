import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';

import './NavBar.css';

import logo from './logo.png';

class NavBar extends Component {
  static propTypes = {
    city: PropTypes.string,
    toggleFeedbackModal: PropTypes.func,
  };

  componentDidMount() {
    document.addEventListener('click', this.clickListener);
  }

  clickListener = ({ target }) => {
    let element = target;

    if (element.tagName === 'line')
      element = target.parentElement.parentElement;
    else if (element.tagName === 'svg') element = target.parentElement;

    const isHamburger =
      element.tagName === 'SPAN' && element.classList.contains('menutrigger');
    const isMenu =
      element.tagName === 'UL' && element.classList.contains('mainmenu');

    if (!isHamburger && !isMenu) {
      this.closeMenuTrigger();
    }
  };

  menuTrigger = () => {
    const wrapper = document.querySelector('.header-wrapper');

    if (wrapper) {
      wrapper.classList.toggle('menu-open');
    }
  };

  closeMenuTrigger = () => {
    const wrapper = document.querySelector('.header-wrapper');

    if (wrapper) {
      wrapper.classList.remove('menu-open');
    }
  };

  render() {
    const { city, toggleFeedbackModal } = this.props;

    return (
      <header className="header-area formobile-menu header--transparent default-color">
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <Link to="/home">
                <img src={logo} alt="UnlockedMaps Logo" />
              </Link>
              <span className="city-name">{city}</span>
            </div>
          </div>
          <div className="header-center">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/home">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li className="has-dropdown">
                  <Link to="/cities">Cities</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header-right">
            <div className="header-btn">
              <Link className="rn-btn" to="/contact">
                <span>Contact Us</span>
              </Link>
            </div>
            <div className="header-btn">
              <span
                onClick={() => toggleFeedbackModal(true)}
                className="rn-btn"
              >
                Provide Feedback
              </span>
            </div>
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.closeMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default NavBar;
