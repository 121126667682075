import PropTypes from 'prop-types';
import { Icon } from 'leaflet';

export const getMarkerIcon = (name, zoom, markerClass) =>
  new Icon({
    iconUrl: require(`./icons/${name}.svg`),
    iconSize: [zoom * 1.8, zoom * 1.8],
    className: markerClass,
  });

getMarkerIcon.propTypes = {
  name: PropTypes.string.isRequired,
  zoom: PropTypes.number,
};

export const getIconClass = (wheelchairAccessible, elevatorOutage) => {
  if (elevatorOutage) {
    return 'elevator-out';
  } else if (wheelchairAccessible) {
    return 'accessible';
  } else {
    return 'not-accessible';
  }
};

getIconClass.propTypes = {
  wheelchairAccessible: PropTypes.bool.isRequired,
  elevatorOutage: PropTypes.string,
};
