import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-parallax';
import queryString from 'query-string';
import flowRight from 'lodash/flowRight';
import { Link, withRouter } from 'react-router-dom';
import { Footer, MetaTags, withTitle } from '../../components';

import './Home.css';

import image from './image.jpg';
import problemImage from './problem.jpg';
import solutionImage from './solution.jpg';
import howCanYouHelpImage from './how-can-you-help.jpg';

const meta = {
  description:
    'Explore urban rail transit with accessibility in mind. View real-time elevator status to plan your commute.',
  title: 'Home',
};

const Section = ({ alignment = 'left', children, className, image, title }) => (
  <div
    className={`rn-columns-area ptb--120 home-section${
      className ? ' ' + className : ''
    }`}
  >
    <div className="container">
      <div className={`home-section-title align-${alignment}`}>
        <h1>{title}</h1>
      </div>
      {alignment === 'left' && (
        <div className="row">
          <div className="col-lg-4 col-md-12">{children}</div>
          <div className="col-lg-8 col-md-12">
            <img
              src={image}
              className={`home-section-image align-${alignment}`}
              alt=""
            />
          </div>
        </div>
      )}
      {alignment === 'right' && (
        <div className="row">
          <div className="col-lg-8">
            <img src={image} alt="" />
          </div>
          <div className="col-lg-4">{children}</div>
        </div>
      )}
    </div>
  </div>
);

Section.propTypes = {
  alignment: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  image: PropTypes.node,
  title: PropTypes.string,
};

class Home extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const uniqueId = queryString.parse(this.props.location.search).uniqueId;

    if (uniqueId != null && uniqueId !== '') {
      window.localStorage.setItem('userUniqueId', uniqueId);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    return (
      <div className="home-content">
        <MetaTags title={meta.title} description={meta.description} />
        <div className="slider-activation slider-creative-agency">
          <Parallax
            bgImage={image}
            strength={1000}
            style={{ width: '100vw', height: '100vh' }}
          >
            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center">
              <div className="parallax-overlay" />
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner text-left banner-container">
                      <h1 className="title theme-gradient">Unlocked Maps</h1>
                      <p className="description">
                        <span>
                          Explore urban rail transit with accessibility in mind.
                        </span>
                        <span>
                          View real-time elevator status to plan your commute.
                        </span>
                      </p>
                      <div className="slide-btn">
                        <Link
                          className="rn-button-style--2 btn-primary-color"
                          to="/cities"
                        >
                          View Cities
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Parallax>
        </div>
        <Section image={problemImage} title="Problem">
          <p>
            Several people rely on the urban rail transit system for their daily
            commute tow work, school, or place of interest. These include people
            who are dependent on the accessibility of the transit station, and
            fully functional elevators. This demographic includes people who use
            wheelchairs, people who have strollers or bikes, and pregnant women.
            More often than desired, they arrive at a station, only to find out
            that either the station isn&apos;t accessible or the elevator has an
            outage. Inconvenience, to describe their frustration, is an
            understatement.
          </p>
        </Section>
        <Section
          image={solutionImage}
          title="Solution"
          alignment="right"
          className="solution-section"
        >
          <p>
            Through UnlockedMaps, we aim to map the urban rail transit stations
            with the central focus on accessibility. UnlockedMaps shows which
            stations are accessible, which stations are not, and which stations
            are currently reporting an elevator outage, using real-time data.
            Commuters are able to make informed decisions regarding their trip,
            civic hackers are able to use our data to develop creative
            solutions, and advocates are able to utilize our analyses to push
            authorities to better their system. We look forward to adding more
            features through community feedback.
          </p>
        </Section>
        <Section image={howCanYouHelpImage} title="How can you help?">
          <p>
            UnlockedMaps would not be possible without the tireless efforts from
            our volunteer <Link to="/about">collaborators</Link>. It is home to
            researchers who are interested in conducting research on urban rail
            transit systems, civic hackers who are looking for contributing to
            this project through their dev and design skills, students who are
            seeking to work on a multi-city accessibility-focused project,
            advocates who are continuously conducting efforts to induce change,
            and anyone who would like to be involved in whatever capacity
            they&apos;re comfortable with. If you&apos;re interested, please{' '}
            <Link to="/contact">reach out to us</Link>.
          </p>
        </Section>
        <Footer />
      </div>
    );
  }
}

export default flowRight(withRouter, withTitle('Home'))(Home);
