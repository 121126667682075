import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import './CheckboxWidget.css';

class CheckboxWidget extends Component {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    schema: PropTypes.object,
    options: PropTypes.shape({
      enumOptions: PropTypes.arrayOf(PropTypes.object),
      labelIcons: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  state = {};

  static getDerivedStateFromProps(nextProps) {
    return {
      data: nextProps.value,
    };
  }

  handleChange = (input) => {
    let data = this.state.data;

    if (input.checked) {
      data = [...data, input.value];
    } else {
      data = data.filter((d) => d !== input.value);
    }

    this.props.onChange(data);
    this.setState({ data });
  };

  getLabelComponent = (label, labelIcon) => {
    if (labelIcon) {
      return (
        <span>
          <i className={`fa fa-${labelIcon}`} />
          {label}
        </span>
      );
    }

    return label;
  };

  render() {
    const { enumOptions, labelIcons = [] } = this.props.options;

    return (
      <div>
        {enumOptions.map((c, index) => (
          <FormControlLabel
            key={c.label}
            control={
              <Checkbox
                value={c.value}
                checked={this.state.data.includes(c.value)}
                color="primary"
                onChange={(e) => this.handleChange(e.target)}
              />
            }
            label={this.getLabelComponent(c.label, labelIcons[index])}
          />
        ))}
      </div>
    );
  }
}

export default CheckboxWidget;
