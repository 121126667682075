import React, { Component } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Form from './components/Form';

import './FeedbackModal.css';

const schema = {
  type: 'object',
  required: ['comment'],
  properties: {
    comment: {
      type: 'string',
      title: 'We appreciate your feedback!',
    },
  },
};

const uiSchema = {
  comment: {
    'ui:placeholder':
      'Enter your comments here. If you would like to collaborate or are interested in onboarding more cities, please reach out to us at ather.sharif@gmail.com. If you would like to participate in a paid follow-up interview, please indicate your contact details (email, phone, etc.).',
    'ui:widget': 'textarea',
    'ui:autofocus': true,
    'ui:showErrors': false,
  },
};

class FeedbackModal extends Component {
  static propTypes = {
    insertFeedback: PropTypes.func,
    showFeedbackModal: PropTypes.bool,
    toggleFeedbackModal: PropTypes.func,
  };

  state = {
    error: null,
    formData: null,
    formSubmitted: false,
  };

  onSubmit = () => {
    const { insertFeedback } = this.props;
    const { formData } = this.state;

    const userId = window.localStorage.getItem('userUniqueId');

    if (!formData.comment || formData.comment.length === 0) {
      this.setState({ error: 'Comment field is empty.' });
      return;
    }

    const options = {
      variables: {
        input: {
          sessionId: parseInt(window.localStorage.getItem('sessionId')),
          comment: formData.comment,
          userId,
        },
      },
    };

    insertFeedback(options)
      .then(() => {
        this.setState({
          error: null,
          formSubmitted: true,
        });
      })
      .catch((e) => {
        this.setState({ error: e.message });
      });
  };

  onChange = ({ formData }) => this.setState({ formData, error: null });

  render() {
    const { error, formData, formSubmitted } = this.state;
    const { showFeedbackModal, toggleFeedbackModal } = this.props;

    return (
      <Modal show={showFeedbackModal}>
        <Modal.Body>
          {error && (
            <Alert variant="danger">
              <strong>Something went wrong!</strong> {error}
            </Alert>
          )}
          {formSubmitted ? (
            <div>
              <Alert variant="success">
                <strong>Feedback successfully submitted. Thank you!</strong>
              </Alert>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState(
                    {
                      formSubmitted: false,
                      formData: null,
                    },
                    () => toggleFeedbackModal(false)
                  );
                }}
              >
                Close
              </button>
            </div>
          ) : (
            <Form
              formData={formData}
              schema={schema}
              uiSchema={uiSchema}
              onChange={this.onChange}
              showErrorList={false}
            >
              <button className="btn btn-primary" onClick={this.onSubmit}>
                Submit
              </button>
              <button
                className="btn btn-default"
                onClick={() => toggleFeedbackModal(false)}
              >
                Cancel
              </button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const insertFeedbackMutation = gql`
  mutation insertFeedback($input: InsertFeedbackInput!) {
    insertFeedback(input: $input) {
      feedback {
        id: rowId
      }
    }
  }
`;

export default graphql(insertFeedbackMutation, { name: 'insertFeedback' })(
  FeedbackModal
);
