import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-leaflet';

import './RestroomPopUp.css';

const RestroomPopUp = ({ restroom, offset }) => (
  <Popup className="restroom-pop-up" offset={offset}>
    <div>
      <h4>{restroom.name}</h4>
      <strong>Located at: </strong>
      {restroom.street}. {restroom.directions}
    </div>
  </Popup>
);

RestroomPopUp.propTypes = {
  offset: PropTypes.arrayOf(PropTypes.number),
  restroom: PropTypes.shape({
    directions: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
  }),
};

export default RestroomPopUp;
