import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet';
import { withApollo } from 'react-apollo';
import HumanInput from 'humaninput';
import StationMarkers from './StationMarkers';
import RestaurantMarkers from './RestaurantMarkers';
import RestroomMarkers from './RestroomMarkers';
import { insertLog } from '../../utils';

// eslint-disable-next-line
eval('window.__VERSION__ = 0');
const HI = new HumanInput(window);

class CustomMap extends Component {
  static propTypes = {
    client: PropTypes.object,
    data: PropTypes.arrayOf(PropTypes.object),
    dataCoords: PropTypes.arrayOf(PropTypes.array),
    defaultZoom: PropTypes.number,
    restaurants: PropTypes.arrayOf(PropTypes.object),
    restrooms: PropTypes.arrayOf(PropTypes.object),
    showPopUp: PropTypes.bool,
    position: PropTypes.arrayOf(PropTypes.number).isRequired,
    wasStationUnselected: PropTypes.bool,
  };

  tileUrl =
    'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png';

  sessionId = null;

  state = { zoom: this.props.data.length > 1 ? undefined : 18 };

  constructor(props) {
    super(props);
    HI.on('click', (e) => {
      insertLog(
        props.client,
        {
          eventType: 'click',
          elementType: e.srcElement ? e.srcElement.localName : null,
          elementName: e.srcElement ? e.srcElement.className : null,
          pageUrl: window.location.href,
        },
        {
          eventTimestamp: e.timeStamp,
          sessionId: this.sessionId,
          updateSessionId: this.updateSessionId,
        }
      );
    });
  }

  static getDerivedStateFromProps(props) {
    const { defaultZoom, wasStationUnselected } = props;

    if (wasStationUnselected) {
      return {
        zoom: defaultZoom,
      };
    }

    return null;
  }

  updateSessionId = (sessionId) => (this.sessionId = sessionId);

  bindMap = (element) => {
    if (element) {
      const map = element.leafletElement;

      map.on('zoomend', () => {
        const { isPopupOpen, zoom } = this.state;

        if (isPopupOpen) {
          map.eachLayer((layer) => layer.unbindPopup());
          map.closePopup();
        }

        const newZoom = map.getZoom();
        if (zoom !== newZoom) {
          this.setState({ zoom: newZoom });
        }
      });

      map.on('popupopen', () => this.setState({ isPopupOpen: true }));
      map.on('popupclose', () => this.setState({ isPopupOpen: false }));

      if (!this.state.zoom) {
        const { defaultZoom } = this.props;

        map.setZoom(defaultZoom);

        this.setState({ zoom: defaultZoom });
      }
    }
  };

  render() {
    const { zoom } = this.state;
    const { data, position, restaurants, restrooms, showPopUp } = this.props;

    const offset = [(-zoom * 1.8) / 2, (-zoom * 1.8) / 3];

    return (
      <Map center={position} ref={this.bindMap} minZoom={7} zoom={zoom}>
        <TileLayer url={this.tileUrl} />
        {data && (
          <StationMarkers
            data={data}
            showPopUp={showPopUp}
            offset={offset}
            zoom={zoom}
          />
        )}
        {restaurants && (
          <RestaurantMarkers
            restaurants={restaurants}
            offset={offset}
            zoom={zoom}
          />
        )}
        {restrooms && (
          <RestroomMarkers restrooms={restrooms} offset={offset} zoom={zoom} />
        )}
      </Map>
    );
  }
}

export default withApollo(CustomMap);
