export default {
  bay_area: {
    name: 'Bay Area',
    state: 'California',
    stateAbbreviation: 'CA',
    defaultZoom: 12,
    defaultCenter: {
      latitude: 37.773972,
      longitude: -122.431297,
    },
  },
  chicago: {
    name: 'Chicago',
    state: 'Illinois',
    stateAbbreviation: 'IL',
    defaultZoom: 13,
    defaultCenter: {
      latitude: 41.881832,
      longitude: -87.690177,
    },
  },
  new_york: {
    name: 'New York',
    state: 'New York',
    stateAbbreviation: 'NY',
    defaultZoom: 13,
    defaultCenter: {
      latitude: 40.73061,
      longitude: -73.935242,
    },
  },
  philadelphia: {
    name: 'Philadelphia',
    state: 'Pennsylvania',
    stateAbbreviation: 'PA',
    defaultZoom: 13,
    defaultCenter: {
      latitude: 39.952583,
      longitude: -75.165222,
    },
  },
  seattle: {
    name: 'Seattle',
    state: 'Washington',
    stateAbbreviation: 'WA',
    defaultZoom: 13,
    defaultCenter: {
      latitude: 47.608013,
      longitude: -122.335167,
    },
  },
  tokyo: {
    name: 'Tokyo',
    state: 'Japan',
    stateAbbreviation: 'JP',
    defaultZoom: 13,
    defaultCenter: {
      latitude: 35.6756241,
      longitude: 139.7672375,
    },
  },
  toronto: {
    name: 'Toronto',
    state: 'Ontario',
    stateAbbreviation: 'ON',
    defaultZoom: 13,
    defaultCenter: {
      latitude: 43.67107,
      longitude: -79.377015,
    },
  },
};
